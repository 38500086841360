<template>
  <div id="StarMix">
    <div class="posFix" ref="posFix">
      <div class="titleTop" >
        <div class="active">星球</div>
        <div @click="changePath('/ActivityCourse?activeName=a')">直播</div>
        <div @click="changePath('/ActivityCourse?activeName=b')">精品课</div>
        <div @click="changePath('/Information')">保观资料库</div>
        <div @click="changePath('/Inspiration')">想法</div>
      </div>
  
      <div class="titleFilter dynamicState block">星球
        <div>
          <span :class="{'active':type == 2}" @click="checkType(2)">最新</span>
          <span :class="{'active':type == 1}" @click="checkType(1)">推荐</span>
        </div>
      </div>
    </div>
    <div class="blackFix" ref="blackFix"></div>
    <!-- 列表 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      class="mixList"
    >
      <div v-for="(i,item) in mixList" :key="item" class="InfoMain">
        <!-- 1 资料 2 想法 3 课程 -->
        <!-- 资料列表 -->
        <div v-if="i.type == 1">
          <div class="doc-first">
            <div>
              <div>
                <img src='../assets/image/home/image1.png' />
                <img src='../assets/image/findOut/blueV.png' />
              </div>
              <div>
                <p>
                  {{i.author}}
                  <img src='../assets/image/findOut/blueTag.png' />
                </p>
              </div>
            </div>
          </div>
          <div class="doc-fifth" @click="toLink(i.doc_file_info.path,i.doc_file_info.id)">
            <img v-if="i.doc_file_info.type == 1" src="../assets/image/home/doc_type_1.png" />
            <img v-else-if="i.doc_file_info.type == 2" src="../assets/image/home/doc_type_2.png" />
            <img v-else-if="i.doc_file_info.type == 3" src="../assets/image/home/doc_type_3.png" />
            <img v-else-if="i.doc_file_info.type == 4" src="../assets/image/home/doc_type_4.png" />
            <img v-else-if="i.doc_file_info.type == 5" src="../assets/image/home/doc_type_5.png" />
            <div>
              <p>{{i.doc_file_info.name}}</p>
              <p>{{i.doc_file_info.ext}} {{i.doc_file_info.size}}</p>
            </div>
          </div>
          
          <div class="doc-forth">
            <div>
              <span>{{Global.clearWhite($moment(new Date(Date.parse((i.publish_at).replace(/-/g, '/')))).fromNow())}}</span>
              <span v-if="i.is_file == 1" class="but">{{i.category.name}}</span>
            </div>
            <div>
              <span>{{i.upvote_count}}
                <van-icon @click.stop="noLikeDoc(i.id,item)" v-if="i.upvote && i.upvote.length" name="good-job" style="margin-left:0.04rem" size=".16rem" color="#FF5562" />
                <van-icon @click.stop="toLikeDoc(i.id,item)" v-else name="good-job-o" style="margin-left:0.04rem" size=".16rem" color="#FF5562" />
              </span>
              <span>
                <van-icon @click.stop="noStar(i.id)" v-if="getCollectIds.indexOf(i.id) != '-1'" name="star" size=".16rem" color="#E59813" />
                <van-icon @click.stop="toStar(i.id)" v-else name="star-o" size=".16rem" color="#E59813" />
              </span>
            </div>
          </div>
        </div>
        <!-- 想法列表 -->
        <div v-if="i.type == 2">
          <div class="first">
            <div>
              <div @click="changePath(`Introduction?id=${i.user_id}`)">
                <img :src="Global.changeImg(i.user.headimgurl)" />
                <img src='../assets/image/findOut/yellowV.png'  />
              </div>
              <div @click="changePath(`Introduction?id=${i.user_id}`)">
                <p class="author-name">{{ i.member_info ? i.member_info.name : i.user.name }} <img src='../assets/image/findOut/grayTag.png'/></p>
              </div>
            </div>
          </div>
          <div class="sixth">
            
            <p class="changehtml" v-html="toBr(i.title)"></p>
            <!-- 图片处理 -->
            <div v-if="i.doc_friend">
              <div v-if="i.doc_friend.thumb2" :class="{'img2': (i.doc_friend.thumb2 && !i.doc_friend.thumb3)|| (i.doc_friend.thumb4 && !i.doc_friend.thumb5),'img3':!((i.doc_friend.thumb2 && !i.doc_friend.thumb3) || (i.doc_friend.thumb4 && !i.doc_friend.thumb5)) || i.doc_friend.thumb2}">
                <div v-if="i.doc_friend.thumb1"><img  @click="showImg(0,item)"  :src="Global.changeImg(i.doc_friend.thumb1)" /></div>
                <div v-if="i.doc_friend.thumb2"><img  @click="showImg(1,item)"  :src="Global.changeImg(i.doc_friend.thumb2)" /></div>
                <div v-if="i.doc_friend.thumb3"><img  @click="showImg(2,item)"  :src="Global.changeImg(i.doc_friend.thumb3)" /></div>
                <div v-if="i.doc_friend.thumb4"><img  @click="showImg(3,item)"  :src="Global.changeImg(i.doc_friend.thumb4)" /></div>
                <div v-if="i.doc_friend.thumb5"><img  @click="showImg(4,item)"  :src="Global.changeImg(i.doc_friend.thumb5)" /></div>
                <div v-if="i.doc_friend.thumb6"><img  @click="showImg(5,item)"  :src="Global.changeImg(i.doc_friend.thumb6)" /></div>
                <div v-if="i.doc_friend.thumb6"><img  @click="showImg(6,item)"  :src="Global.changeImg(i.doc_friend.thumb6)" /></div>
                <div v-if="i.doc_friend.thumb8"><img  @click="showImg(7,item)"  :src="Global.changeImg(i.doc_friend.thumb8)" /></div>
                <div v-if="i.doc_friend.thumb9"><img  @click="showImg(8,item)"  :src="Global.changeImg(i.doc_friend.thumb9)" /></div>
                <div v-if="i.doc_friend.thumb5 && !i.doc_friend.thumb6"></div>
                <div v-if="i.doc_friend.thumb8 && !i.doc_friend.thumb9"></div>
              </div>
              <div v-else-if="i.doc_friend.thumb1" class="img1">
                <img  @click="showImg(0,item)"  :src="Global.changeImg(i.doc_friend.thumb1)" />
              </div>
            </div>
          </div>
          <div class="forth">
            <div>
              <span>{{Global.clearWhite($moment(new Date(Date.parse((i.created_at).replace(/-/g, '/')))).fromNow())}}</span>
            </div>
            <div>
              <span>{{i.praise}}
                <van-icon @click.stop="noLike(i.id,item)" v-if="i.upvote" name="good-job" style="margin-left:0.04rem" size=".16rem"  color="#FF5562" />
                <van-icon @click.stop="toLike(i.id,item)" v-else name="good-job-o" style=";margin-left:0.04rem" size=".16rem" color="#FF5562" />
              </span>
              <span>{{i.share_comments.length}}
                <van-icon @click.stop="preComment(i.id, item)" name="chat-o" style="margin-left:0.04rem" size=".16rem" color="#EEA54C" />
              </span>
            </div>
          </div>
          <div class="comments" v-if="i.share_comments.length">
            <!-- TODO 点击评论内容回复评论，点自己的显示删除 -->
            <p v-for="(comment,c) in i.share_comments" :key="c" @click="preComment(i.id, item, comment.id, comment.member_info)">
              <span class="commentator" @click.stop="changePath(`Introduction?id=${comment.user_id}`)">{{ comment.member_info.name }}</span>
              <span v-if="comment.reply_id" style="margin-left: .01rem">回复<span class="commentator" @click.stop="changePath(`Introduction?id=${comment.reply_id}`)">{{ comment.reply_member_info.name }}</span></span>：{{ comment.content }}
            </p>
          </div>
        </div>
        <!-- 精品课列表 -->
        <div v-if="i.type == 3">
          <div class="doc-first">
            <div>
              <div>
                <img src='../assets/image/home/image1.png' />
                <img src='../assets/image/findOut/blueV.png' />
              </div>
              <div>
                <p>
                  保观
                  <img src='../assets/image/findOut/blueTag.png' />
                </p>
              </div>
            </div>
          </div>
          <div class="co-fifth" @click.stop="toCoursesDetail(i.id)">
            <div>
              <div>{{i.name}}</div>
              <div>{{i.summary}}</div>
              <div v-if="i.tag">{{i.tag.name}}</div>
              <div>
                <span>¥{{i.price}}<span>{{i.pay_log_count}}人已学习</span></span>
                <span v-if="i.pay_log">{{i.pay_log.length? '已订阅' :'立即订阅'}}</span>
              </div>
            </div>
          </div>
          
          <div class="doc-forth">
            <div>
              <span>{{Global.clearWhite($moment(new Date(Date.parse((i.created_at).split('.')[0].replace('T', ' ').replace(/-/g, '/')))).fromNow())}}</span>
              <span class="but">精品课</span>
            </div>
          </div>
        </div>
      </div>
    </van-list>

    <van-popup v-model="showComment" position="bottom"  >
      <div class="editSure">
        <van-field
          class="edit"
          v-model="commentPayload.content"
          rows="2"
          autosize
          type="textarea"
          maxlength="120"
          :placeholder="commentPayload.commented ? `回复${commentPayload.commented.name}：` : '评论'"
          show-word-limit
          @keydown="submitComment"
          @blur="clearComment"
        />
      </div>
    </van-popup>
  </div>
</template>
<script>
import start from '../assets/image/findOut/start.png'
import startn from '../assets/image/findOut/startn.png'
import { ImagePreview } from 'vant';
export default {
  name: "StarMix",
  data(){
    return{
      mixList:[],//想法列表
      user: '',
      finished:false,
      loading:true,
      next_page_url:'',
      sort:0,
      type:2,
      start:0,
      getCollectIds:[],//收藏的文件id
      showComment: false,
      commentPayload: {}
    }
  },
  created(){
    this.Global.getShareConfig({
      title: '保观资料库  | 保观大咖会',
      desc: '幸运的是，今天的我们不用担心过于专注而被野兽吃掉；不幸的是，知识爆炸的今天我们必须学会过滤信息。'
    },this)
  },
  mounted() {
    this.loading = true
    // 用户信息
    this.axios({
      method: 'GET',
      url: '/user/getUserDetail',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        //1:非会员  2:会员 3:会员过期 4:会员已锁定 5：保观官方
        this.user = res.data.data
      }
    })

    // 资源列表
    this.axios({
      method: 'GET',
      url: '/share/getStarList?type=2&start=0',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.loading = false
        this.mixList = res.data.data
        this.start++
      }
    })
  },
  methods:{
    // 图片预览
    showImg(i,index){
      var tableData = this.mixList[index].doc_friend
      console.log(tableData,index,i)
      
      var showArryImg = []
      if(tableData.image1){
        showArryImg.push(tableData.image1)
      }
      if(tableData.image2){
        showArryImg.push(tableData.image2)
      }
      if(tableData.image3){
        showArryImg.push(tableData.image3)
      }
      if(tableData.image4){
        showArryImg.push(tableData.image4)
      }
      if(tableData.image5){
        showArryImg.push(tableData.image5)
      }
      if(tableData.image6){
        showArryImg.push(tableData.image6)
      }
      if(tableData.image7){
        showArryImg.push(tableData.image7)
      }
      if(tableData.image8){
        showArryImg.push(tableData.image8)
      }
      if(tableData.image9){
        showArryImg.push(tableData.image9)
      }
      ImagePreview({images:showArryImg,startPosition:i});
      // console.log(showArryImg)
      
    },
    checkType(a){
      this.type = a
      this.start = 0
      this.loading = true
      this.finished = false
      this.axios({
        method: 'GET',
        url: '/share/getStarList?type='+this.type+'&start='+this.start,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.mixList = []
          this.mixList = res.data.data
          this.start++
          this.loading = false
        }
      })
    },
    changePath(path){
      this.path = path
      this.$router.push(path)
    },
    // 下滑加载
    onLoad(){
      if(this.start != 0){
        this.loading = true
        this.axios({
          method: 'GET',
          url: '/share/getStarList?type='+this.type+'&start='+this.start,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data.length){
              this.mixList.push(...res.data.data)
              this.start++
            }else{
              this.start = 0
            }
            if(!res.data.data.length || !res.data.data){
              this.finished = true
            } 
          }else{
            this.loading = false;
            this.finished = true
          }
        })
      }else{
        this.loading = false;
      }
    },
    toLike(shareId,index){
      this.axios({
        method: 'PATCH',
        url: `/share/upvoteShare/${shareId}`,
        data:{
          status:1
        },
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.mixList[index].upvote = {id:shareId}
          this.mixList[index].praise++
          this.$toast({
            message: '点赞成功',
            icon: 'thumb-circle-o',
          })
        }
      })
    },
    noLike(shareId,index){
      this.axios({
        method: 'PATCH',
        url: `/share/upvoteShare/${shareId}`,
        data:{
          status:0
        },
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          // var index = this.getCollectIds.indexOf(shareId);
          this.mixList[index].upvote = null
          this.mixList[index].praise--
          this.$toast({
            message: '取消点赞'
          })
        }
      })
    },
    toLink(link,id){
      if(this.user.status == 2 || (this.user.status == 8 && this.user.is_activate == 1)){
        this.axios({
          method: 'PATCH',
          url: '/doc/addDocViewCount/'+id,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then(() => {
          window.location.href = link
        }) 
      }else{
        let msg = '';

        if (this.user.status == 8) {
          msg = '请先激活会员'
        } else {
          msg = '请先购买或激活会员'
        }
        this.$toast(msg)
        var _this = this;
        setTimeout(function(){
          if (_this.user.status == 8) {
            _this.$router.push('/EditMessage')
          } else {
            _this.$router.push('/MemberProduct')
          }
          
        },2000)
      }
      // MemberProduct
    },
    toStar(docId){
      this.axios({
        method: 'PATCH',
        url: '/doc/collectFile',
        data:{
          status:1,
          docId:docId
        },
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.getCollectIds.push(docId)
          this.$toast({
            message: '收藏成功',
            icon: start,
          })
        }
      })
    },
    noStar(docId){
      this.axios({
          method: 'PATCH',
          url: '/doc/collectFile?sort='+this.sort+'&docId='+docId,
          data:{
            status:0,
            docId:docId
          },
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            var index = this.getCollectIds.indexOf(docId);
            this.getCollectIds.splice(index, 1);
            this.$toast({
              message: '取消收藏',
              icon: startn,
            })
          }
        })
    },  
    toLikeDoc(docId,index){
      this.axios({
        method: 'POST',
        url: '/doc/upvoteDoc',
        data:{
          status:1,
          docId:docId
        },
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.mixList[index].upvote.push([{id:docId}])
          this.mixList[index].upvote_count++
          this.$toast({
            message: '点赞成功',
            icon: start,
          })
        }
      })
    },
    noLikeDoc(docId,index){
      this.axios({
          method: 'POST',
          url: 'doc/upvoteDoc',
          data:{
            status:0,
            docId:docId
          },
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.mixList[index].upvote = []
            this.mixList[index].upvote_count--
            this.$toast({
              message: '取消点赞',
              icon: startn,
            })
          }
        })
    },  
    toCoursesDetail(id){
      this.$router.push({
        path: '/CoursesDetail?seriesId='+id,
      })
    },
    toBr(str) {
      str = str.replace(/\r\n/g,"<br>")
      str = str.replace(/\n/g,"<br>");

      return str
    },
    preComment(shareId, index, commentId = null, commentator = null){
      if (this.user.status == 2 || (this.user.status == 8 && this.user.is_activate == 1)) {
        this.commentPayload.shareId = shareId
        // TODO 评论其他人的评论
        if (commentId) {
          console.log(commentator)
          if (commentator.submit_id === this.user.id) {
            // TODO 显示删除
            this.$dialog.confirm({
              title: '删除评论？',
              confirmButtonText: '删除',
              cancelButtonText: '取消',
              confirmButtonColor: '#ee0a24',
              cancelButtonColor: '#3890FF',
            }).then(() => {
              this.delComment(index, commentId)
            });

            return false
          }

          this.commentPayload.commented = commentator
        }
        this.commentPayload.index = index
        this.showComment = true
      } else {
        this.$toast({
          message: '只有会员可以评论哦~'
        })
      }
    },
    clearComment() {
      this.commentPayload = {}
    },
    delComment(index, commentId) {
      // TODO 删除数据接口
      let _this = this
      
      this.axios({
        method: 'DELETE',
        url: `/share/deleteUserComment/${commentId}`,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          _this.mixList[index].share_comments = _this.mixList[index].share_comments.filter(t => t.id != commentId)
          _this.$toast('已删除')
        } else {
          _this.$toast(res.data.message)
        }
      })
    },
    submitComment(event) {
      let _this = this;

      if (event.keyCode === 13) {
        // 阻止浏览器默认换行操作
        event.preventDefault(); 

        // 提交数据
        this.axios({
          method: 'POST',
          url: '/share/addShareComment',
          data: {
            content: this.commentPayload.content,
            shareId: this.commentPayload.shareId,
            replyId: this.commentPayload.commented ? this.commentPayload.commented.id : null
          },
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            // 评论内容添加 TODO 返回评论信息
            _this.mixList[_this.commentPayload.index].share_comments.push(res.data.data)
            // 隐藏评论框
            _this.showComment = false
            // 清除评论内容
            _this.clearComment()
          } else {
            _this.$toast(res.data.message)
          }
        })

        return false;
      }
    }
  }
};
</script>
<style lang="less" scoped>
#StarMix {
  .titleTop {
    background: #23252E;
    padding: 0.15rem;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    border-bottom: .1px solid #5C5D6E61;
    color: #989ab1;
    > div {
      margin-right: 0.2rem;
      position: relative;
    }
    .active {
      font-weight: 600;
      color: #f6f7fd;
      font-size: 0.14rem;
    }
    .active::after {
      position: absolute;
      content: "";
      height: 3px;
      background: #3890ff;
      left: 0;
      right: 0;
      margin: auto 0.06rem;
      bottom: -0.05rem;
      border-radius: 3px;
    }
  }
  .addMore{
    position: fixed;
    bottom: 0.8rem;
    width: 0.45rem;
    height: 0.45rem;
    background: linear-gradient(345deg, #3890FF 0%, #1B78EE 100%);
    box-shadow: 0px 0px 5px rgba(9, 104, 223, 0.39);
    border-radius: 50%;
    right: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      font-weight: 900;
    }
  }
  .posFix{
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 750px;
    background: #16171D;
    z-index: 10;
    >div{
      background: #23252E;
    }
  }
  .blackFix{
    z-index: -1;
    background: #16171D;
    height: 5rem;
    top: 20px;
    position: fixed;
    width: 100%;
    max-width: 750px;
  }
  .mixList{
    margin-top: 1.13rem;
  }
  .InfoMain{
    padding: 0;
    >div{
      margin-top: .1rem;
      padding: 0.18rem 0.15rem;
      position: relative;
      border-bottom: .1px solid #5C5D6E61;
      >img{
        position:absolute;
        top: 0;
        width: 0.25rem;
        left: 0;
      }
      .doc-fifth{
        background: #3D404F;
        border-radius: 0.05rem;
        padding: 0.11rem 0.15rem;
        display: flex;
        justify-content: flex-start;
        margin: -0.12rem 0 0.12rem 0.47rem;
        >div{
          >p:last-child{
            color: #989AB1;
            font-size: 0.12rem;
          }
          >p:first-child{
            color: #F6F7FD;
            font-size: 0.14rem;
            margin-bottom: 0.05rem;
          }
        }
        >img{
          width: 0.3rem;
          margin-right: 0.16rem;
        }
      }
      .co-fifth {
        background: #3D404F;
        border-radius: 0.05rem;
        padding: 0.11rem 0.15rem;
        display: flex;
        justify-content: flex-start;
        margin: -0.12rem 0 0.12rem 0.47rem;
        >div{
          display: flex;
          flex-direction: column;
          flex: 1;
          color: #F6F7FD;
          overflow: hidden;
          >div:last-child{
            display: flex;
            align-items: center;
            justify-content: space-between;
            >span:first-child{
              color: #FF4A4A;
              font-size: 0.12rem;
              >span{
                color: #989AB1;
                margin-left: 0.05rem;
                font-size: 0.12rem;
                transform: scale(0.8);
              }
            }
            >span:last-child{
              height: 0.25rem;
              background: #3890FF;
              color: #FFFFFF;
              text-align: center;
              line-height: 0.25rem;
              padding: 0 0.13rem;
              font-size: 0.12rem;
              border-radius: 0.25rem;
              transform: scale(0.9);
              margin-top: -0.08rem;
            }
          }
          >div:nth-child(3){
            color: #727387;
            font-size: 0.12rem;
          }
          >div:nth-child(2){
            color: #9091A3;
            font-size: 0.12rem;
            margin: 0.04rem 0 0.06rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          >div:first-child{
            // display: flex;
            color: #F6F7FD;
            font-size: 0.14rem;
            line-height: 0.16rem;
            margin-bottom: 0.02rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .doc-forth{
        margin-left: 0.47rem;
        >div:last-child{
          display: flex;
          >span{
            margin-left: 0.1rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.14rem;
          }
        }
        >div:first-child{
          display: flex;
          >span:first-child{
            color: #989AB1;
            font-size: 0.14rem;
            margin: 0 0.04rem 0 0;
          }
          >span.but{
            color: #9091A3;
            padding: 0 0.05rem;
            background: #3D404F;
            border-radius: 0.02rem;
            margin-right: 0.04rem;
            font-size: 0.14rem;
          }
        }
      }
      .doc-first{
        >div{
          flex: 1;
          color: #989AB1;
          display: flex;
          font-size: .16rem;
          // align-items: center;
          >div:last-child{
            flex: 1;
            margin-top: -0.02rem;
            >p{
              margin: 0 0.04rem 0 0;
              margin-left: 0.1rem;
              display: flex;
              font-weight: bold;
              >img{
                height: .14rem;
                margin-top: .05rem;
                margin-left: .06rem;
              }
            }
          }
          >div:first-child{
            position: relative;
            height: 0.37rem;
            >img:first-child{
              width: 0.37rem;
              height: 0.37rem;
              border-radius: 50%;
            }
            >img:last-child{
              position: absolute;
              width: 0.16rem;
              bottom: -0.03rem;
              right: -0.0rem;
            }
          }
        }
      }

      .sixth{
        display: block;
        margin: -0.16rem 0 0.12rem 0.51rem;
        >div>div{
          flex-wrap: wrap;
          display: flex;
          padding-right: .2rem;
        }
        .changehtml {
          color: #ECEDF7;
          line-height: 0.22rem;
          font-size: 0.16rem;
        }
        .img1{
          margin-top: 0.08rem;
          img{
            max-height: 188px;
            max-width: 60%;
          }
        }
        .img3,.img6,.img7,.img9,.img8,.img5{
          margin-top: 0.08rem;
          justify-content: space-between;
          img{
            min-width: 100%;
            max-width: 200%;
            min-height: 100%;
          }
          div{
            width:  33%;
            margin-top: 3px;
            position: relative;
            padding-bottom: 33%;
            height: 0;
            overflow: hidden;
          }
        }
        .img4,.img2{
          margin-top: 0.08rem;
          width: 60%;
          justify-content: space-between;
          img{
            min-width: 100%;
            max-width: 200%;
            min-height: 100%;
            // clip: rect(0px,10px,10px,0px);
            position: absolute;
          }
          div{
            width: 49.3%;
            margin-top: 3px;
            position: relative;
            padding-bottom: 49.3%;
            height: 0;
            overflow: hidden;
          }
        }
      }
      .fifth{
        background: #3D404F;
        border-radius: 0.05rem;
        padding: 0.11rem 0.15rem;
        display: flex;
        justify-content: flex-end;
        margin: 0.15rem 0 0.12rem 0.37rem;
        >div{
          >p:last-child{
            color: #989AB1;
            font-size: 0.12rem;
          }
          >p:first-child{
            color: #F6F7FD;
            font-size: 0.14rem;
            margin-bottom: 0.05rem;
          }
        }
        >img{
          width: 0.3rem;
          margin-right: 0.16rem;
        }
      }
      .forth{
        margin-left: 0.51rem;
        >div:last-child{
          display: flex;
          >span{
            margin-left: 0.2rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.14rem;
          }
        }
        >div:first-child{
          display: flex;
          >span:first-child{
            color: #989AB1;
            font-size: 0.14rem;
            margin: 0 0.04rem 0 0;
          }
          >span.but{
            color: #9091A3;
            padding: 0 0.05rem;
            background: #3D404F;
            border-radius: 0.02rem;
            margin-right: 0.04rem;
            font-size: 0.14rem;
          }
        }
      }
      .comments {
        margin-left: 0.51rem;
        margin-top: 0.1rem;
        padding: 0.03rem 0.06rem;
        color: #9fa1b8;
        background: #2d2f3a;
        border-radius: 0.03rem;
        display: block;
        p {
          &:not(:last-child) {
            margin-bottom: 0.02rem;
          }
          font-size: .15rem;
          span.commentator {
            &:last-child {
              margin-left: 0.01rem;
            }
            color: #3890FF;
            font-weight: bold;
          }
        }
      }
      .first{
        >div{
          flex: 1;
          color: #989AB1;
          font-size: 0.16rem;
          display: flex;
          // align-items: center;
          >div:last-child{
            margin-top: -0.02rem;
            flex: 1;
            >p{
              margin: 0 0.04rem 0 0;
              margin-left: 0.1rem;
              display: flex;
              font-weight: bold;
              >img{
                height: .14rem;
                margin-top: .05rem;
                margin-left: .06rem;
              }
            }
          }
          >div:first-child{
            position: relative;
            height: 0.4rem;
            >img:first-child{
              width: 0.4rem;
              height: 0.4rem;
              border-radius: 50%;
            }
            >img:last-child{
              position: absolute;
              width: 0.16rem;
              bottom: -0.03rem;
              right: -0.0rem;
            }
          }
        }
        >span{
          color: #727387;
          font-size: 0.14rem;
        }
      }
      >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .sort{
    padding: 0.15rem 0.3rem 0.05rem;
    >p{
      color: #989AB1;
      font-size: 0.12rem;
      margin-bottom: 0.12rem;
    }
    .active{
      color: #3890FF;
    }
  }
  .interests{
    display: flex;
    justify-content: space-between;
    >span{
      color: #989AB1;
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      margin-right: 0.05rem;
      >img{
        width: 0.12rem;
        margin-right: 0.05rem;
      }
    }
  }
  .titleTop2{
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #989AB1;
    font-size: 0.14rem;
    font-weight: 600;
    >span{
      color: #F6F7FD;
      font-size: 0.12rem;
    };
  }
  .titleTab{
    display: flex;
    flex-wrap: wrap;
    padding: 0.15rem 0.15rem 0.1rem;
    >div{
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      background: #3D404F;
      color: #989AB1;
      font-size: 0.12rem;
      margin-right: 0.12rem;
      margin-bottom: 0.1rem;
    }
    .active{
      background: #3890FF;
      color: #F6F7FD;
    }
  } 
  .topPop{
    .anscontain{
      display: flex;
      height: 0.4rem;
      border-top: 1px solid #5C5D6E61;
      >span{
        flex:1;
        text-align: center;
        line-height: 0.4rem;
        color: #989AB1;
        font-size: 0.14rem;
      }
      >span:nth-child(2){
        color: #3890FF;
        border-left: 1px solid #5C5D6E61;
      }
    }
  }
  
  .personalContent{
    margin-top: 0.1rem;
  }
  .titleFilter {
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    font-size: 0.16rem;
    font-weight: 600;
  }
  .dynamicState{
    position: sticky;
    top: 0;
    margin-bottom: 0;
    z-index: 2;
    border-bottom: .1px solid #5C5D6E61;
    >div{
      .active{
        position: relative;
        color: #F6F7FD;
      }
      .active::after{
        position:absolute;
        content: '';
        width: 100%;
        height: 3px;
        background: #3890FF;
        left: 0;
        bottom: -0.06rem;
        border-radius: 3px;
      }
      >span{
        margin-left: 0.18rem;
        font-size: 0.14rem;
        color: #9091A3;
      }
    }
  }
  .hotMain{
    .forth{
      display: flex;
      justify-content: space-between;
      padding: .15rem;
        >div:last-child{
          display: flex;
          >span{
            margin-left: 0.2rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
            >img{
              width: 0.12rem;
              margin:0.02rem 0.05rem 0 0.13rem;
            }
          }
          .white{
            color: #F6F7FD;
          }
        }
        >div:first-child{
          color: #F6F7FD;
          font-size: 0.16rem;
        }
      }
  }
  .editSure{
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #16171D;
  }
  >div{
    background: #23252E;
  }
}
</style>